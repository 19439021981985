<template>
  <a-layout class="custom-layout">
    <a-layout-sider class="custom-sider">
      <system-menu></system-menu>
    </a-layout-sider>
    <a-spin :spinning="pageLoading" style="width: 100%;height: 100%;">
      <a-layout-content class="custom-content auto" style="position:relative;">
        <div class="drawer-bar auto">
          <div class="fl">
            <span class="font16"><b>账号安全策略</b></span>
          </div>
        </div>
        <div class="department-content auto" id="no-margin-form2">
          <a-form-model
            ref="ruleForm"
            :model="form"
            :label-col="{span: 2}"
            :wrapper-col="{span: 15}"
          >
            <div class="other-box auto">
              <div class="other-title auto">
                正常登录
              </div>
              <div class="other-con">
                <a-form-model-item label="认证成功" prop="min">
                  <a-switch size="small" v-model="form.loginNormal.authenticationSuccessRemind" @click="putNotification($event, 'authenticationSuccessRemind')"></a-switch><span>是否提醒</span>
                  <a-switch size="small" v-model="form.loginNormal.authenticationSuccessDisplayConfig" @click="putNotification($event, 'authenticationSuccessDisplayConfig')"></a-switch><span>是否显示配置</span>
                  <a-switch size="small" v-model="form.loginNormal.authenticationSuccessAllowModify" @click="putNotification($event, 'authenticationSuccessAllowModify')"></a-switch><span>是否允许修改</span>
                </a-form-model-item>
                <a-form-model-item label="登入成功" prop="max">
                  <a-button size="small" @click="openSetting">设置</a-button>
                </a-form-model-item>
              </div>
            </div>
            <div class="other-box auto">
              <div class="other-title auto">
                账号变更
              </div>
              <div class="other-con">
                <a-form-model-item label="修改密码" prop="min">
                  <a-switch size="small" v-model="form.accountChange.modifyPasswordRemind" @click="putNotification($event, 'modifyPasswordRemind')"></a-switch><span>是否提醒</span>
                  <a-switch size="small" v-model="form.accountChange.modifyPasswordDisplayConfig" @click="putNotification($event, 'modifyPasswordDisplayConfig')"></a-switch><span>是否显示配置</span>
                  <a-switch size="small" v-model="form.accountChange.modifyPasswordAllowModify" @click="putNotification($event, 'modifyPasswordAllowModify')"></a-switch><span>是否允许修改</span>
                </a-form-model-item>
                <a-form-model-item label="修改手机" prop="min">
                  <a-switch size="small" v-model="form.accountChange.modifyPhoneRemind" @click="putNotification($event, 'modifyPhoneRemind')"></a-switch><span>是否提醒</span>
                  <a-switch size="small" v-model="form.accountChange.modifyPhoneDisplayConfig" @click="putNotification($event, 'modifyPhoneDisplayConfig')"></a-switch><span>是否显示配置</span>
                  <a-switch size="small" v-model="form.accountChange.modifyPhoneAllowModify" @click="putNotification($event, 'modifyPhoneAllowModify')"></a-switch><span>是否允许修改</span>
                </a-form-model-item>
                <a-form-model-item label="修改邮箱" prop="min">
                  <a-switch size="small" v-model="form.accountChange.modifyEmailRemind" @click="putNotification($event, 'modifyEmailRemind')"></a-switch><span>是否提醒</span>
                  <a-switch size="small" v-model="form.accountChange.modifyEmailDisplayConfig" @click="putNotification($event, 'modifyEmailDisplayConfig')"></a-switch><span>是否显示配置</span>
                  <a-switch size="small" v-model="form.accountChange.modifyEmailAllowModify" @click="putNotification($event, 'modifyEmailAllowModify')"></a-switch><span>是否允许修改</span>
                </a-form-model-item>
              </div>
            </div>
            <div class="other-box auto">
              <div class="other-title auto">
                账号异常
              </div>
              <div class="other-con">
                <a-form-model-item label="暴力破解" prop="min">
                  <a-switch size="small" v-model="form.accountAbnormal.bruteforceAttackRemind" @click="putNotification($event, 'bruteforceAttackRemind')"></a-switch><span>是否提醒</span>
                  <a-switch size="small" v-model="form.accountAbnormal.bruteforceAttackDisplayConfig" @click="putNotification($event, 'bruteforceAttackDisplayConfig')"></a-switch><span>是否显示配置</span>
                  <a-switch size="small" v-model="form.accountAbnormal.bruteforceAttackAllowModify" @click="putNotification($event, 'bruteforceAttackAllowModify')"></a-switch><span>是否允许修改</span>
                </a-form-model-item>
                <a-form-model-item label="弱密码" prop="min">
                  <a-switch size="small" v-model="form.accountAbnormal.weakPasswordRemind" @click="putNotification($event, 'weakPasswordRemind')"></a-switch><span>是否提醒</span>
                  <a-switch size="small" v-model="form.accountAbnormal.weakPasswordDisplayConfig" @click="putNotification($event, 'weakPasswordDisplayConfig')"></a-switch><span>是否显示配置</span>
                  <a-switch size="small" v-model="form.accountAbnormal.weakPasswordAllowModify" @click="putNotification($event, 'weakPasswordAllowModify')"></a-switch><span>是否允许修改</span>
                </a-form-model-item>
              </div>
            </div>
          </a-form-model>
        </div>
      </a-layout-content>
    </a-spin>
    <a-drawer
      width="100%"
      title=""
      class="access-drawer"
      placement="right"
      :closable="false"
      :visible="visible"
      :get-container="false"
      :wrap-style="{ position: 'absolute' }"
      >
      <a-layout class="content">
        <a-layout-sider class="custom-sider">
          <a-button class="back-btn" size="small" @click="back"><a-icon type="double-left" class="font12" />返回</a-button>
          <div class="batch-menu">
            <ul>
              <li class="cur">系统和提醒设置</li>
            </ul>
          </div>
        </a-layout-sider>
        <a-layout-content class="custom-content auto" style="position:relative;">
          <a-spin :spinning="loading">
            <div class="content-title auto">
              <div class="coustom-title fl">系统和提醒设置（{{count}}个）</div>
            </div>
            <div class="content-bar auto">
              <custom-page :total="count" @getPageMes="getPageMes" :page="filter.current_page" :limit="filter.page_size">
                <div slot="custom">
                  <a-button type="primary" size="small" @click="addSetting"> 添加单独设置 </a-button>
                </div>
              </custom-page>
            </div>
            <div class="content-table auto">
              <a-table :rowKey="(record,index)=>{return index}" :columns="tablecolumns" :data-source="tabledata" size="middle" :pagination="false">
                <div slot="serviceRemind" slot-scope="text, record, index">
                  <a-switch v-model="tabledata[index]['serviceRemind']" size="small" @click="changeService(record)"></a-switch>
                </div>
                <div slot="serviceDisplayConfig" slot-scope="text, record, index">
                  <a-switch v-model="tabledata[index]['serviceDisplayConfig']" size="small" @click="changeService(record)"></a-switch>
                </div>
                <div slot="serviceAllowModify" slot-scope="text, record, index">
                  <a-switch v-model="tabledata[index]['serviceAllowModify']" size="small" @click="changeService(record)"></a-switch>
                </div>
                <div slot="action" slot-scope="text, record">
                  <a-popconfirm
                    title="确定要删除吗?"
                    ok-text="确定"
                    cancel-text="取消"
                    placement="topRight"
                    @confirm="disabledOff(record)"
                  >
                    <a-button type="primary" size="small" class="bgred borred">删除</a-button>
                  </a-popconfirm>
                </div>
              </a-table>
            </div>
            <div class="content-bar auto">
              <custom-page :total="count" @getPageMes="getPageMes" :page="filter.current_page" :limit="filter.page_size">
                <div slot="custom">
                  <a-button type="primary" size="small" @click="addSetting"> 添加单独设置 </a-button>
                </div>
              </custom-page>
            </div>
          </a-spin>
        </a-layout-content>
      </a-layout>
    </a-drawer>
    <a-modal v-model="addSysVisible" title="添加单独设置" @cancel="addSysClose" @ok="addSysSubmit" width="430px">
      <a-row>
        <a-col :span="5">选择系统：</a-col>
        <a-col :span="19">
          <a-select v-model="serviceId" placeholder="请选择系统名称" show-search option-filter-prop="children" size="small" allowClear style="width:100%;">
            <a-select-option :value="labelSelectItem.id" v-for="(labelSelectItem, labelSelectIndex) in serviceList" :key="labelSelectIndex" :disabled="Boolean(tabledata.find(item => {return item.serviceid == labelSelectItem.id}))">{{labelSelectItem.name}}</a-select-option>
          </a-select>
        </a-col>
      </a-row>
    </a-modal>
  </a-layout>
</template>

<script>
import { System } from '@/model'
export default {
  data () {
    return {
      pageLoading: false,
      addSysVisible: false,
      visible: false,
      menuIndex: 1,
      form: {
        loginNormal: {
          authenticationSuccessRemind: true,
          authenticationSuccessDisplayConfig: true,
          authenticationSuccessAllowModify: true
        },
        accountChange: {
          modifyPasswordRemind: true,
          modifyPasswordDisplayConfig: true,
          modifyPasswordAllowModify: true,
          modifyPhoneRemind: true,
          modifyPhoneDisplayConfig: true,
          modifyPhoneAllowModify: true,
          modifyEmailRemind: true,
          modifyEmailDisplayConfig: true,
          modifyEmailAllowModify: true
        },
        accountAbnormal: {
          bruteforceAttackRemind: true,
          bruteforceAttackDisplayConfig: true,
          bruteforceAttackAllowModify: true,
          weakPasswordRemind: true,
          weakPasswordDisplayConfig: true,
          weakPasswordAllowModify: true
        }
      },
      loading: false,
      tablecolumns: [
        {
          title: '系统名称',
          dataIndex: 'servicename'
        },
        {
          title: '是否提醒',
          dataIndex: 'serviceRemind',
          scopedSlots: { customRender: 'serviceRemind' }
        },
        {
          title: '是否显示配置',
          dataIndex: 'serviceDisplayConfig',
          scopedSlots: { customRender: 'serviceDisplayConfig' }
        },
        {
          title: '是否允许修改',
          dataIndex: 'serviceAllowModify',
          scopedSlots: { customRender: 'serviceAllowModify' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      tabledata: [],
      count: 0,
      filter: {
        page_size: 10,
        current_page: 1
      },
      serviceList: [],
      serviceId: undefined
    }
  },
  methods: {
    addSysClose () {
      this.addSysVisible = false
      this.serviceId = undefined
    },
    async addSysSubmit () {
      let serviceName = this.serviceList.find(item => {
        return item.id === this.serviceId
      })
      let res = await System.addService({
        serviceid: this.serviceId,
        servicename: serviceName['name'],
        serviceRemind: true,
        serviceDisplayConfig: true,
        serviceAllowModify: true
      })
      if (!res['code']) {
        this.$message.success('添加成功！')
        this.addSysClose()
        this.getNotificationList()
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    addSetting () {
      this.addSysVisible = true
    },
    openSetting () {
      this.visible = true
    },
    back () {
      this.visible = false
    },
    getPageMes (obj) {
      this.filter = {
        current_page: obj.pageNumber,
        page_size: obj.pageSize
      }
      this.getNotificationList()
    },
    async changeService (row) {
      let res = await System.changeService(row)
      if (!res['code']) {
        this.$message.success('修改成功！')
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    async disabledOff (row) {
      let res = await System.deleteService(row.serviceid)
      if (!res['code']) {
        this.$message.success('删除成功！')
        this.getNotificationList()
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    async getNotification () {
      this.pageLoading = true
      let res = await System.getNotification()
      this.pageLoading = false
      if (!res['code']) {
        this.form = {
          loginNormal: {
            authenticationSuccessRemind: res['data']['loginNormal'] && res['data']['loginNormal']['authenticationSuccessRemind'] ? true : false,
            authenticationSuccessDisplayConfig:  res['data']['loginNormal'] && res['data']['loginNormal']['authenticationSuccessDisplayConfig'] ? true : false,
            authenticationSuccessAllowModify:  res['data']['loginNormal'] && res['data']['loginNormal']['authenticationSuccessAllowModify'] ? true : false
          },
          accountChange: {
            modifyPasswordRemind: res['data']['accountChange'] && res['data']['accountChange']['modifyPasswordRemind'] ? true : false,
            modifyPasswordDisplayConfig: res['data']['accountChange'] && res['data']['accountChange']['modifyPasswordDisplayConfig'] ? true : false,
            modifyPasswordAllowModify: res['data']['accountChange'] && res['data']['accountChange']['modifyPasswordAllowModify'] ? true : false,
            modifyPhoneRemind: res['data']['accountChange'] && res['data']['accountChange']['modifyPhoneRemind'] ? true : false,
            modifyPhoneDisplayConfig: res['data']['accountChange'] && res['data']['accountChange']['modifyPhoneDisplayConfig'] ? true : false,
            modifyPhoneAllowModify: res['data']['accountChange'] && res['data']['accountChange']['modifyPhoneAllowModify'] ? true : false,
            modifyEmailRemind: res['data']['accountChange'] && res['data']['accountChange']['modifyEmailRemind'] ? true : false,
            modifyEmailDisplayConfig: res['data']['accountChange'] && res['data']['accountChange']['modifyEmailDisplayConfig'] ? true : false,
            modifyEmailAllowModify: res['data']['accountChange'] && res['data']['accountChange']['modifyEmailAllowModify'] ? true : false
          },
          accountAbnormal: {
            bruteforceAttackRemind: res['data']['accountAbnormal'] && res['data']['accountAbnormal']['bruteforceAttackRemind'] ? true : false,
            bruteforceAttackDisplayConfig: res['data']['accountAbnormal'] && res['data']['accountAbnormal']['bruteforceAttackDisplayConfig'] ? true : false,
            bruteforceAttackAllowModify: res['data']['accountAbnormal'] && res['data']['accountAbnormal']['bruteforceAttackAllowModify'] ? true : false,
            weakPasswordRemind: res['data']['accountAbnormal'] && res['data']['accountAbnormal']['weakPasswordRemind'] ? true : false,
            weakPasswordDisplayConfig: res['data']['accountAbnormal'] && res['data']['accountAbnormal']['weakPasswordDisplayConfig'] ? true : false,
            weakPasswordAllowModify: res['data']['accountAbnormal'] && res['data']['accountAbnormal']['weakPasswordAllowModify'] ? true : false
          }
        }
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    async putNotification (e, name) {
      this.pageLoading = true
      let res = await System.putNotification(name, e ? 'open' : 'close')
      this.pageLoading = false
      if (!res['code']) {
        this.$message.success('修改成功！')
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    async getNotificationList () {
      let res = await System.getNotificationList(this.filter)
      if (!res['code']) {
        this.tabledata = res['data']
        this.count = res['total']
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    async getServiceCode () {
      let res = await System.getServiceCode('service')
      if (!res['code']) {
        this.serviceList = res['data']
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    }
  },
  created () {
    this.getNotification()
    this.getNotificationList()
    this.getServiceCode()
  },
  mounted () {
  }
};
</script>

<style scoped>
.drawer-bar {
  padding: 8px 0 10px;
}

.other-box {
  border-top: 1px solid rgba(0,0,0,0.06);
  padding: 10px 0;
}
.other-title {
  font-size: 16px;
  color: rgba(0,0,0,0.50);
  padding: 10px 0 20px;
}
.other-con {
  width: 800px;
}
.other-con span {
  margin-right: 20px;
  margin-left: 5px;
}


.content-bar {
  padding: 8px 0;
}
.batch-menu {
  width: 100%;
  padding-top: 50px;
}
.batch-menu ul li {
  line-height: 50px;
  cursor: pointer;
  font-size: 16px;
  color: rgba(0,0,0,0.65);
  text-indent: 40px;
}
.batch-menu ul li.cur {
  color: rgba(0,0,0,0.85);
  font-weight: bold;
  background: #E6F7FF;
}
</style>
